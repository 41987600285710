import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PaginationCustom, PanelHeader, Spinner } from '../../components';
import { getCookie, setCookie } from '../../helpers/cookies';
import { all, getCsv } from '../../helpers/actions/projects';
import ProjectCard from './ProjectCard';
import Filters from './Filters';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      count: 0,
      page: 1,
      limit: 12,
      provider: null,
      status: null,
      supervisor: null,
      seller: null,
      search: '',
      type: null,
      sort: 'updated_at',
      order: 1,
      openCanvas: false
    };
  }

  load() {
    this.setState({ loading: true });

    let {
      page,
      limit,
      provider,
      status,
      search,
      sort,
      order,
      type,
      supervisor,
      seller
    } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(
      all({
        limit,
        offset,
        provider,
        status,
        search,
        sort,
        dir: order,
        type,
        supervisor,
        seller
      })
    )
      .then(({ data, meta }) =>
        this.setState({
          projects: data,
          ...meta,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.setState({ loading: true });

    let { page, limit, search, order, sort } = this.state,
      { dispatch, match } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;
    let paramStatus = match.params.status ? match.params.status : null;
    let provider = getCookie('filterProvider');

    Promise.all([
      dispatch(
        all({
          limit,
          offset,
          provider,
          search,
          status: paramStatus,
          order,
          sort
        })
      )
    ])
      .then(([{ data, meta }]) => {
        this.setState({
          projects: data,
          ...meta,
          loading: false,
          provider
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  getCsv(from = 0) {
    let { dispatch } = this.props;
    this.setState(st => ({ ...st, loading: true }));

    dispatch(getCsv({ from }))
      .then(res => {
        if (res?.url && window) {
          window.open(res.url, '_blank');
        }
      })
      .catch(err => {
        console.log('script err ' + err.message);
      }).finally(() => {
        this.setState(st => ({ ...st, loading: false }));
      })
  }

  executeFilter = (name, value, cookies) => {
    if (cookies) setCookie(cookies, value, 90);

    this.setState(
      ps => ({
        ...ps,
        page: 1,
        [name]: value
      }),
      () => this.load()
    );
  };

  render() {
    let { loading, projects, page, limit, count, sort, order } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}

          <Filters
            order={order}
            sort={sort}
            match={this.props.match}
            filter={(name, value, cookies) =>
              this.executeFilter(name, value, cookies)
            }
          />

          <Row>
            <Col xs={12} id={'scripts-buttons'} className={'d-none'}>
              <Button
                className={'mb-2 mr-2'}
                color="info"
                onClick={() => this.getCsv(0)}
              >
                <Trans>Export 0 - 1000</Trans>
              </Button>
              <Button
                className={'mb-2 mr-2'}
                color="info"
                onClick={() => this.getCsv(1000)}
              >
                <Trans>Export 1000 - 2000</Trans>
              </Button>
              <Button
                className={'mb-2 mr-2'}
                color="info"
                onClick={() => this.getCsv(2000)}
              >
                <Trans>Export 2000 - 3000</Trans>
              </Button>
              <Button
                className={'mb-2 mr-2'}
                color="info"
                onClick={() => this.getCsv(3000)}
              >
                <Trans>Export 3000 - 4000</Trans>
              </Button>
            </Col>
          </Row>

          {projects.length > 0 ? (
            <div>
              <Row>
                {projects.map((project, key) => (
                  <Col key={key} xs={12} md={3}>
                    <ProjectCard project={project} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
              <Row>
                <Col xs={12} md={12}>
                  <Card>
                    <CardBody>
                      <p className={'text-not-found text-center'}>
                        <Trans>No projects found</Trans>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Home));
