import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { all as allProviders } from '../../helpers/actions/providers';
import { all } from '../../helpers/actions/users';
import { orders, projectSorts, projectType } from '../../helpers/nomenclators';
import { supervisorName, sellerName } from '../../helpers/formatters';
import Select from 'react-select';
import { getCookie } from '../../helpers/cookies';
import { loadStatuses } from '../../helpers/actions/projects';
import { Accordion } from '../../components';
let filtering = null;

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      provider: '',
      statuses: [],
      status: '',
      supervisors: [],
      supervisor: '',
      sellers: [],
      seller: '',
      search: '',
      type: '',
      sort: '',
      order: ''
    };
  }

  componentWillReceiveProps() { }

  componentWillMount() {
    let { dispatch, match, order, sort } = this.props;
    let paramStatus =
      match && match.params && match.params.status ? match.params.status : null;
    let provider = getCookie('filterProvider');

    Promise.all([
      dispatch(allProviders({ limit: 100 })),
      dispatch(loadStatuses()),
      dispatch(all({ limit: 1000, role: 'supervisor' })),
      dispatch(all({ limit: 1000, role: 'seller' }))
    ])
      .then(([providers, { status }, supervisors, sellers]) => {
        this.setState({
          providers: providers.data,
          provider,
          statuses: status,
          status: paramStatus,
          supervisors: supervisors.data,
          sellers: sellers.data,
          order,
          sort
        });
      })
      .catch(() => { });
  }

  executeFilter = (name, value, cookies) => {
    filtering = setTimeout(() => this.props.filter(name, value, cookies), 500);
  }

  setFilter = (name, value, cookies) => {
    clearTimeout(filtering);
    this.executeFilter(name, value, cookies)
    this.setState(ps => ({
      ...ps,
      [name]: value
    }));
  };

  render() {
    let {
      providers,
      provider,
      statuses,
      status,
      supervisors,
      supervisor,
      sellers,
      seller,
      search,
      type,
      sort,
      order
    } = this.state;
    let { t } = this.props;

    return (
      <Card className={'mb-0 mb-md-3'}>
        <div className={'d-none d-md-inline'}>
          <CardBody>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Search</Trans>
                  </Label>
                  <Input
                    type="text"
                    value={search}
                    onChange={event =>
                      this.setFilter('search', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Type</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={projectType.map(pt => ({
                      label: t(pt),
                      value: pt
                    }))}
                    value={type}
                    onChange={event =>
                      this.setFilter('type', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup>
                  <Label>
                    <Trans>Provider</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={providers.map(p => ({
                      label: p.name,
                      value: p._id
                    }))}
                    value={provider ? provider : null}
                    onChange={event => {
                      this.setFilter(
                        'provider',
                        event ? event.value : null,
                        'filterProvider'
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup>
                  <Label>
                    <Trans>Supervisor</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={supervisors.map(sup => ({
                      label: supervisorName(sup),
                      value: sup._id
                    }))}
                    value={supervisor}
                    onChange={event =>
                      this.setFilter('supervisor', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup>
                  <Label>
                    <Trans>Seller</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={sellers.map(sup => ({
                      label: sellerName(sup),
                      value: sup._id
                    }))}
                    value={seller}
                    onChange={event =>
                      this.setFilter('seller', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Status</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={statuses.map(s => ({ label: t(s), value: s }))}
                    value={status}
                    onChange={event =>
                      this.setFilter('status', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={6} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Sort</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={projectSorts.map(s => ({
                      label: t(s.label),
                      value: s.value
                    }))}
                    value={sort}
                    onChange={event =>
                      this.setFilter('sort', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={6} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Order</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={orders.map(s => ({
                      label: t(s.label),
                      value: s.value
                    }))}
                    value={order}
                    onChange={event =>
                      this.setFilter('order', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col className={'text-right'} xs={12} md={3}>
                <Link to={'/projects/new'} className="btn btn-info m-0 mt-4">
                  <Trans>Add</Trans>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </div>
        <div className={'d-inline d-md-none'}>
          <CardBody>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Search</Trans>
                  </Label>
                  <Input
                    type="text"
                    value={search}
                    onChange={event =>
                      this.setFilter('search', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Accordion
              key={'projects-list-advanced-filters'}
              plain
              defaultOpened={-1}
              components={[
                {
                  title: (
                    <h6 style={{ display: 'inline' }}>
                      <Trans>More Filters</Trans>
                    </h6>
                  ),
                  text: (
                    <Row>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Type</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={projectType.map(pt => ({
                              label: t(pt),
                              value: pt
                            }))}
                            value={type}
                            onChange={event =>
                              this.setFilter('type', event ? event.value : null)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Provider</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={providers.map(p => ({
                              label: p.name,
                              value: p._id
                            }))}
                            value={provider ? provider : null}
                            onChange={event => {
                              this.setFilter(
                                'provider',
                                event ? event.value : null,
                                'filterProvider'
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Supervisor</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={supervisors.map(sup => ({
                              label: supervisorName(sup),
                              value: sup._id
                            }))}
                            value={supervisor}
                            onChange={event =>
                              this.setFilter(
                                'supervisor',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Seller</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={sellers.map(sup => ({
                              label: sellerName(sup),
                              value: sup._id
                            }))}
                            value={seller}
                            onChange={event =>
                              this.setFilter(
                                'seller',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Status</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={statuses.map(s => ({
                              label: t(s),
                              value: s
                            }))}
                            value={status}
                            onChange={event =>
                              this.setFilter(
                                'status',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Sort</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={projectSorts.map(s => ({
                              label: t(s.label),
                              value: s.value
                            }))}
                            value={sort}
                            onChange={event =>
                              this.setFilter('sort', event ? event.value : null)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Order</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={orders.map(s => ({
                              label: t(s.label),
                              value: s.value
                            }))}
                            value={order}
                            onChange={event =>
                              this.setFilter(
                                'order',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )
                }
              ]}
            />
            <Row>
              <Col className={'text-right'} xs={12} md={3}>
                <Link to={'/projects/new'} className="btn btn-sm btn-info m-0">
                  <Trans>Add</Trans>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(Filters));
